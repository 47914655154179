import React from "react";
import Star from "../../../../static/images/star.png";
const RatingReview = (props: any) => {
    const { title, subtitle } = props;
    return (
        <>
            <div className="container mx-auto px-4">
                <section className="internet-section4">                
                    <h2 className="h2 text-secondary text-center">{title}</h2>
                    <p className="p2 text-black text-center mb-12">{subtitle}</p>
                    <div className="max-width1000 grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                        <div className="internet-section4__box">
                            <h4 className="h4">Adam G, Halifax, Nova Scotia</h4>
                            <div className="star-row">
                                <img src={Star} alt="Star" className="star-icon" />
                                <img src={Star} alt="Star" className="star-icon" />
                                <img src={Star} alt="Star" className="star-icon" />
                                <img src={Star} alt="Star" className="star-icon" />
                                <img src={Star} alt="Star" className="star-icon" />
                            </div>
                            <p className="p2">Purple cow is the only internet provider you need, and the only one you should consider. The internet service itself is fast and reliable - I don't have issues streaming, working from home, etc. Also, the customer service is unmatched. They connect by phone call, text, or video call. That connection is quick, so no long wait times on a phone. I always feel like positive reviews read as generic, but this is the truth. Purple Cow is the best. A+, 10/10, 💯.</p>
                        </div>
                        <div className="internet-section4__box">
                            <h4 className="h4">Shane W, Charlottetown, Prince Edward Island</h4>
                            <div className="star-row">
                                <img src={Star} alt="Star" className="star-icon" />
                                <img src={Star} alt="Star" className="star-icon" />
                                <img src={Star} alt="Star" className="star-icon" />
                                <img src={Star} alt="Star" className="star-icon" />
                                <img src={Star} alt="Star" className="star-icon" />
                            </div>
                            <p className="p2">Not usually the type to post reviews but the service with this group warrants one. From first text to purple cow to streaming our first movie only took 3 days. Everyone was very helpful and set up was a breeze, they even checked our signal strength and did a follow up to make sure we were happy with everything. Thanks to all.</p>
                        </div>
                        <div className="internet-section4__box">
                            <h4 className="h4">Monica R, Dartmouth, Nova Scotia</h4>
                            <div className="star-row">
                                <img src={Star} alt="Star" className="star-icon" />
                                <img src={Star} alt="Star" className="star-icon" />
                                <img src={Star} alt="Star" className="star-icon" />
                                <img src={Star} alt="Star" className="star-icon" />
                                <img src={Star} alt="Star" className="star-icon" />
                            </div>
                            <p className="p2">Honestly, the internet is just as good as any other service in Halifax. In my experience it matches Bell and Eastlink. The customer service has been spectacular for me. That was the real winner. Try calling Bell and it’s a mess of being transferred and wait times… I found it so easy to communicate with Purple Cow customer service.</p>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default RatingReview
