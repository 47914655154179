import React, { Fragment, useState } from "react";
import { Accordion, AccordionHeader, AccordionBody, } from "@material-tailwind/react";


function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`${id === open ? "rotate-180" : ""
                } h-7 w-7 transition-transform`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="#fbbd69"
            strokeWidth={2}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
        </svg>
    );
}


const CommonQuestion = () => {
    const [open, setOpen] = useState(0);

    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };

    return (
        <>
            <section className="tv-section8">
                <div className="container mx-auto px-4">
                    <h2 className="h2 text-secondary text-center">Some common internet questions</h2>
                    <div className="max-width1000">
                        <Fragment>
                            <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(1)}>
                                    How long does it take to get turned on?
                                </AccordionHeader>
                                <AccordionBody>
                                    Usually everything is complete within 3-4 business days unless a tech is required. We use local technicians to your area so depending on availability if a tech is required it can take a little longer. Normally a tech is only required if you are in a new construction or if you know there is no coax in your home.
                                </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 2} icon={<Icon id={2} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(2)}>
                                    Will the Wifi get everywhere in my home?
                                </AccordionHeader>
                                <AccordionBody>
                                    We provide a Wifi router for free with your system. The router is awesome and sends out both 5G and 2.4G wifi. If you are having trouble getting the wifi all around your home there are several trouble shooting steps we can work with you on to make sure it works great in your home. 
                                </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 3} icon={<Icon id={3} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(3)}>
                                    Can I adjust my packages?
                                </AccordionHeader>
                                <AccordionBody>
                                    Super easy to adjust your package. Just shoot us a text and we will make any changes you like.
                                </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 4} icon={<Icon id={4} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(4)}>
                                    Do I need the Gig internet plan?
                                </AccordionHeader>
                                <AccordionBody>
                                    Every herd member is different however most of the time the Gig plan provides more bandwidth than you need. We always suggest starting on a lower package and working your way up if needed. 
                                </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 5} icon={<Icon id={5} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(5)}>
                                    With prices starting at $60 is the internet any good?
                                </AccordionHeader>
                                <AccordionBody>
                                    Our Internet is great. Check out our google reviews if you don't believe us. It is the same internet as the big guys only less expensive. 
                                </AccordionBody>
                            </Accordion>
                        </Fragment>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CommonQuestion
